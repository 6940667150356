import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | CraftStories - шкіряні витвори мистецтва
			</title>
			<meta name={"description"} content={"Ласкаво просимо до CraftStories. Тут пристрасть поєднується з точністю. З непохитною прихильністю до майстерності, ми вплітаємо історії в кожну нитку, кожен стібок, створюючи не просто аксесуари, а спадщину, яку ви можете носити з собою."} />
			<meta property={"og:title"} content={"Головна | CraftStories - шкіряні витвори мистецтва"} />
			<meta property={"og:description"} content={"Ласкаво просимо до CraftStories. Тут пристрасть поєднується з точністю. З непохитною прихильністю до майстерності, ми вплітаємо історії в кожну нитку, кожен стібок, створюючи не просто аксесуари, а спадщину, яку ви можете носити з собою."} />
			<meta property={"og:image"} content={"https://estriter.live/img/00.jpg"} />
			<link rel={"shortcut icon"} href={"https://estriter.live/img/6000699.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://estriter.live/img/6000699.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://estriter.live/img/6000699.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://estriter.live/img/6000699.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://estriter.live/img/6000699.png"} />
			<meta name={"msapplication-TileImage"} content={"https://estriter.live/img/6000699.png"} />
			<meta name={"msapplication-TileColor"} content={"https://estriter.live/img/6000699.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 120px 0" background="--color-lightD2" quarkly-title="Team-15">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="46px 24px"
				md-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-rows="auto"
				sm-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 50px 0px 0px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					sm-padding="0px 0 0px 0px"
					sm-margin="0px 0px 15px 0px"
				>
					<Text margin="0px 0px 25px 0px" color="--orange" font="normal 300 20px/1.2 --fontFamily-mono">
						Вироби зі шкіри
					</Text>
					<Text margin="0px 0px 30px 0px" color="--darkL2" font="normal 900 48px/1.2 --fontFamily-sans">
						CraftStories
					</Text>
					<Text margin="0px 0px 40px 0px" color="--darkL2" font="300 18px/140% --fontFamily-sansHelvetica">
						Ласкаво просимо до CraftStories. Тут пристрасть поєднується з точністю. З непохитною прихильністю до майстерності, ми вплітаємо історії в кожну нитку, кожен стібок, створюючи не просто аксесуари, а спадщину, яку ви можете носити з собою.
					</Text>
					<Button
						background="--color-orange"
						padding="15px 44px 15px 44px"
						font="normal 400 14px/1.5 --fontFamily-sansHelvetica"
						href="/services"
						type="link"
						text-decoration-line="initial"
					>
						Відкрити Більше &gt;&gt;
					</Button>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://estriter.live/img/t6.jpg"
						display="block"
						width="100%"
						height="420px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
					/>
					<Text margin="0px 0px 15px 0px" color="--darkL2" font="normal 400 22px/1.2 --fontFamily-sans">
						Ремені
					</Text>
					<Text margin="0px 0px 0px 0px" color="--grey">
						Не просто необхідність, але й висловлювання престижу.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://estriter.live/img/t4.jpg"
						display="block"
						width="100%"
						height="420px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
					/>
					<Text margin="0px 0px 15px 0px" color="--darkL2" font="normal 400 22px/1.2 --fontFamily-sans">
						Гаманці
					</Text>
					<Text margin="0px 0px 0px 0px" color="--grey">
						Витонченість і стиль у кожній деталі.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-width="100%"
				>
					Наші унікальні особливості
				</Text>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					lg-width="100%"
				>
					Створіть своє бачення разом з нами. Індивідуальні замовлення - це місце, де ваша уява зустрічається з нашим досвідом. Поділіться своїм дизайном, оберіть шкіру і дозвольте нам створити магію.
				</Text>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 32px 0px"
					lg-width="50%"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 16px 0px 0px"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						background="--color-orange"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
					>
						1
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Нитки пам'яті
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Ви коли-небудь відчували зв'язок з певною датою, місцем чи моментом? Дозвольте нам непомітно викарбувати його на обраному вами аксесуарі. Ваші секрети, наше ремесло.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 32px 0px"
					lg-width="50%"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 16px 0px 0px"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						background="--color-orange"
						border-radius="50px"
						display="flex"
						align-items="center"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
					>
						2
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Природні недоліки
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Кожен шматок шкіри, який ми використовуємо, має свою історію. Прийміть природні дефекти, адже вони є свідченням автентичності.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					lg-width="50%"
					lg-padding="0px 0px 0px 16px"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						background="--color-orange"
						border-radius="50px"
						display="flex"
						align-items="center"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
					>
						3
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Виберіть свій стібок
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Пориньте в нашу палітру ниток. Від приглушених земляних тонів до яскравих відтінків - персоналізуйте аж до стібка.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://estriter.live/img/00.jpg"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
				/>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-2">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				justify-content="center"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				width="75%"
			>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 65% 0px"
					width="100%"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						position="absolute"
						display="block"
						right={0}
						min-height="100%"
						bottom={0}
						src="https://estriter.live/img/01.jpg"
						object-fit="cover"
						width="100%"
						top={0}
						left={0}
					/>
				</Box>
			</Box>
			<Box
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				justify-content="space-between"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="25%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
			>
				<Text
					margin="0px 0px 40px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Розкішний не означає недосяжний. Ми пропонуємо конкурентоспроможні ціни, не економлячи на якості та майстерності. Ми віримо у відкриту комунікацію, починаючи з нашої політики і закінчуючи нашим ремеслом. Знайте, що ви купуєте і як це зроблено.{" "}
				</Text>
				<Box
					height="243px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="243px"
					overflow-y="hidden"
					border-radius="100%"
				>
					<Image
						right={0}
						bottom={0}
						min-height="100%"
						src="https://estriter.live/img/02.jpg"
						object-fit="cover"
						width="100%"
						left={0}
						position="absolute"
						display="block"
						top={0}
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});